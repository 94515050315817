@import "~bootstrap/scss/bootstrap.scss";
@import "./app.scss";
@import "./pages/index.scss";

:root {
    --bs-body-bg: #dcdcdc;
}

html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#svasthaCircleApp {
    display: flex;
    width: 100%;
    height: 100%;
    place-items: stretch stretch;
    flex-direction: column;
}

a {
    text-decoration: none;
    position: relative;
}

a:after {
    content: "";
    position: absolute;
    left: 0;
    border-bottom: 1px solid rgba(var(--bs-link-color-rgb), 1);
    bottom: -5px;
    transition: all ease-in 200ms;
    width: 0;
}

a:hover::after,
a:active::after {
    width: 100%;
}
