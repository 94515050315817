.home {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    background-color: #fff;

    &__form {
        width: 100%;
    }

    &__column {
        &--one {
            padding: 3rem 0;
            align-items: center;
            justify-content: center;
            display: flex;
            overflow: hidden;
            flex-direction: column;
            background: #0a0065;
            background: url("../../img/login-bg.png") center bottom no-repeat;
            background-size: cover;
            height: 100px;

            img {
                width: auto;
                height: 80px;
            }
        }

        &--two {
            overflow: auto;
            display: block;
        }
    }

    &__content {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        min-height: calc(100vh - 140px);
        padding: 2rem;
    }

    footer {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0.5rem;
    }

    @media only screen and (min-width: 992px) {
        flex-direction: row;

        &__column {
            height: auto;

            &--one {
                width: 30%;
                img {
                    height: auto;
                    width: 80%;
                    max-width: 500px;
                }
            }

            &--two {
                width: 70%;
            }
        }

        &__content {
            height: auto;
            min-height: calc(100vh - 40px);
            align-items: center;
        }

        footer {
            text-align: right;
        }
    }

    @media only screen and (min-width: 1200px) {
        &__column {
            width: 50%;
        }
    }
}
